import AccountRoutes from 'containers/AccountModule/AccountRoutes'
import BookingRoutes from 'containers/BookingModule/BookingRoutes'
import HomeRoutes from 'containers/HomeModule/HomeRoutes'
import DebtorProfileRoutes from 'containers/DebtorProfileModule/DebtorProfileRoutes'
import React from 'react'
import { Redirect } from 'react-router-dom'
import AccountStatusRoutes from 'containers/AccountStatusModule/AccountStatusRoutes'

const StartRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/home" />,
  },
]

const ErrorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
]

const routes = [
  ...HomeRoutes,
  ...AccountRoutes,
  ...BookingRoutes,
  ...DebtorProfileRoutes,
  ...AccountStatusRoutes,
  ...StartRoute,
  ...ErrorRoute,
]

export default routes
