import { ApolloProvider } from '@apollo/react-hooks'
import DateFnsUtils from '@date-io/date-fns'
import '@ifca-root/react-component/src/assets/styles/app.scss' // react component styles
import theme from '@ifca-root/react-component/src/assets/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers/'
import 'assets/styles/app.scss' //local styles
import { client, Uploadclient } from 'client'
import Layout from 'components/Layout/Layout'
import { createBrowserHistory } from 'history'
import React, { useEffect, useReducer } from 'react'
import { Router } from 'react-router-dom'
import { GuestLayout } from './GuestLayout'
import Routes from './Router/Routes'
import AppContext from './Store/AppContext'
import { GlobalInitialState, RootReducer } from './Store/RootReducer'
export const history = createBrowserHistory()

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState)
  // useEffect(() => {
  //   if (localStorage.getItem('HotelID')) {
  //     dispatch({
  //       type: 'hotelOrGuest',
  //       payload: 'Hotel',
  //     })
  //   } else {
  //     dispatch({
  //       type: 'hotelOrGuest',
  //       payload: 'Guest',
  //     })
  //   }
  // }, [dispatch])
  // useEffect(() => {
  //   localStorage.setItem('localState', JSON.stringify(globalState))
  // }, [globalState])

  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <ApolloProvider client={Uploadclient}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={history}>
                <Layout>
                  <Routes />
                </Layout>
              </Router>
            </MuiPickersUtilsProvider>
          </ApolloProvider>
        </ApolloProvider>
      </ThemeProvider>
    </AppContext.Provider>
  )
}
export default App
