import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  InputBase,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Close, Search, Mic } from '@material-ui/icons'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'
interface ISearchActionHeaderProps {
  title?: string
  notFixed?: boolean
  multiple?: boolean
  AllList?: boolean
  lengthNumber?: number
  value?: any
  onClick?: any
  option?: {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    icon?: any
  }
  search?: boolean
  onChangeAction?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCloseAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
  autoComplete?: React.ReactNode
  setFilterSearch?: any
  filterSearch?: string
  mic?: boolean
  dialog?: boolean
}
export const SearchActionHeader = (props: ISearchActionHeaderProps) => {
  const {
    title,
    value,
    search,
    option,
    onChangeAction,
    onCloseAction,
    notFixed,
    multiple,
    AllList,
    lengthNumber,
    autoComplete,
    setFilterSearch,
    filterSearch,
    mic,
    dialog,
  } = props
  // console.log(
  //   '🚀 ~ file: SearchActionHeader.tsx ~ line 40 ~ SearchActionHeader ~ lengthNumber',
  //   lengthNumber
  // )

  const [searchBar, setSearch] = useState(false)
  const [Empty, setEmpty] = useState(false)
  const { transcript, listening, resetTranscript } = useSpeechRecognition()

  const handleSearchToggle = () => {
    setSearch(!searchBar)
    setEmpty(false)
  }

  console.log(transcript)
  const handleStartListening = () =>
    SpeechRecognition.startListening({
      continuous: true,
      language: 'en-GB',
    })
  const handleStopListening = () => {
    SpeechRecognition.stopListening()
  }
  useEffect(() => {
    if (transcript && !dialog) {
      setFilterSearch(transcript)
    }
  }, [transcript])
  const handleStopMic = () => {
    handleStopListening()
    resetTranscript()
  }
  return (
    <List
      id="search-header"
      className={
        AllList
          ? `${lengthNumber < 4
            ? lengthNumber === 3
              ? 'fixed-length4-header'
              : lengthNumber === 2
                ? 'fixed-length-header'
                : lengthNumber === 1
                  ? 'fixed-length3-header'
                  : 'fixed-length2-header'
            : 'fixed-searchAction-header'
          } search-header`
          : [
            'search-header',
            `${notFixed
              ? ''
              : multiple
                ? 'fixed-multiple-search-header'
                : 'fixed-search-header'
            }`,
          ].join(' ')
      }
    >
      <ListItem onClick={handleSearchToggle}>
        <ListItemText
          disableTypography
          primary={
            <>
              <span className="xsTitle flex-space">
                {title || 'Search here...'}{' '}
                {value && (
                  <>
                    (<span className="highlight-text">{value}</span>)
                  </>
                )}
              </span>
            </>
          }
        />
        <ListItemSecondaryAction>
          {search ? (
            <IconButton
              edge="end"
              aria-label="search"
              // onClick={el.onClick}
              onClick={handleSearchToggle}
            >
              <Search />
            </IconButton>
          ) : null}
          {option && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={option?.onClick}
            >
              {option?.icon}
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className={`search-field ${searchBar ? null : 'hide'}`}>
        <ListItemText
          disableTypography
          primary={
            <>
              {autoComplete ? (
                autoComplete
              ) : (
                <InputBase
                  placeholder="Search here.."
                  inputProps={{ 'aria-label': 'search', autoComplete: 'off' }}
                  value={filterSearch}
                  onChange={onChangeAction}
                  autoComplete="off"
                  inputRef={input => {
                    // input && searchBar && input.focus()
                    input && Empty && (input.value = '')
                  }}
                />
              )}
            </>
          }
        />
        <ListItemSecondaryAction>
          {mic && (
            <IconButton
              onClick={
                !listening || dialog
                  ? handleStartListening
                  : handleStopListening
              }
              color={!listening || dialog ? 'default' : 'secondary'}
              // onClick={e => {
              //   // handleSearchToggle()
              //   // onCloseAction(e)
              //   // setEmpty(true)
              // }}
              className={`close-search ${searchBar ? null : 'hide'}`}
            >
              <Mic />
            </IconButton>
          )}
          <IconButton
            edge="end"
            aria-label="close"
            onClick={e => {
              handleSearchToggle()
              onCloseAction(e)
              setEmpty(true)
              handleStopMic()
            }}
            className={`close-search ${searchBar ? null : 'hide'}`}
          >
            <Close />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}
