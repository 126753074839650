// import { getAccessToken } from 'helpers/AccessToken'
import { useLoggedInDebtorProfileQuery } from 'generated/graphql'
import { useSnackBar } from 'helpers/hooks/useSnackBar'
import React from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'

export const PrivateRoute = ({ children, ...rest }) => {
  // const AccessToken = getAccessToken()
  const token = JSON.parse(localStorage.getItem('DebtorInfo'))?.accessToken
  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()
  const history = useHistory()
  const {
    data: { loggedInDebtorProfile } = { loggedInDebtorProfile: null },
  } = useLoggedInDebtorProfileQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        snackFunc(
          message === 'Not Authenticated'
            ? 'Login session expired, Please login again.'
            : message,
          false
        )

        if (message === 'Not Authenticated') {
          setTimeout(() => {
            history.push('login')
            localStorage.clear()
          }, 2000)
        }
      })
    },
  })
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
