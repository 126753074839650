import React from 'react'
interface ContentWrapperProps {
  children?: any
  footer?: boolean
  page?: string
  float?: boolean
  floatTotalAmt?: boolean
  search?: boolean
  multiDynamicInfo?: boolean
  singleDynamicInfo?: boolean
  dynamicInfoSearch?: boolean
  multiDynamicInfoSearch?: boolean
  multiDynamicSubInfoSearch?: boolean
  tabs?: boolean
}
export const ContentWrapper = (props: ContentWrapperProps) => {
  const {
    children,
    footer,
    search,
    float,
    floatTotalAmt,
    multiDynamicInfo,
    singleDynamicInfo,
    dynamicInfoSearch,
    multiDynamicInfoSearch,
    tabs,
    multiDynamicSubInfoSearch,
  } = props
  var footing = ''

  if (footer == true && float == true) {
    footing = 'with-footer-n-float'
  } else if (footer == true && float == null) {
    footing = 'with-footer'
  } else if (footer == null && float == true) {
    footing = 'with-float'
  } else if (footer == true && floatTotalAmt == true) {
    footing = 'with-float-amt'
  }
  //  else if (footer == null && search == true) {
  //   footing = 'with-search'
  // }
  return (
    <div
      className={`content-wrapper ${footing} 
      ${tabs && 'with-tab'}
      ${search && 'with-search'} 
      ${multiDynamicInfo && `multiple-dynamicInfo`}
      ${singleDynamicInfo && `single-dynamicInfo`}
      ${dynamicInfoSearch && `dynamicinfo-search`}
      ${multiDynamicInfoSearch && `multi-dynamicinfo-search`}
      ${multiDynamicSubInfoSearch && `multi-dynamicSubinfo-search`}
      `}
      // style={{ marginTop: search ? '38px' : null }}
    >
      {children}
    </div>
  )
}
