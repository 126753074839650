import JSEncrypt from 'jsencrypt'
const publicKey = `
-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCYew+xTQWimCKAaLKuVXEptqcL
d+jF8046lyrn8Ow8+fCuWZlLnrdeAcjMyyFsQ+PS6nJnPTGtXFiEdUQ9elPPqYrx
xnMS4pZxDuuq75Y9hEL4HsYON1+5gVQNJuv/xhGxXq6nmjE5aSOeMQq1fcralcdK
P90sJSdGAZpiNNYSLwIDAQAB
-----END PUBLIC KEY-----
`
export function encryptMessage(message) {
  const jsEncrypt = new JSEncrypt()
  jsEncrypt.setPublicKey(publicKey)
  return jsEncrypt.encrypt(message)
}

/// Reference  library
// https://github.com/travist/jsencrypt
