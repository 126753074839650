import { createContext, Dispatch } from 'react'
import { GlobalInitialState, GlobalState } from './RootReducer'

const AppContext = createContext<{
  globalState: GlobalState
  dispatch: Dispatch<any>
}>({
  globalState: GlobalInitialState,
  dispatch: () => null,
})
export default AppContext
