import React, { lazy } from 'react'

const NotFound = lazy(() =>
  import('./NotFound').then(module => ({
    default: module.NotFound,
  }))
)

const Profile = lazy(() =>
  import('./Profile').then(module => ({
    default: module.Profile,
  }))
)
const EditProfile = lazy(() =>
  import('./EditProfile').then(module => ({
    default: module.EditProfile,
  }))
)
const ChangePassword = lazy(() =>
  import('./ChangePassword').then(module => ({
    default: module.ChangePassword,
  }))
)
const AccountRoutes = [
  // {
  //   props: { path: '/login' },
  //   component: <Login />,
  // },
  {
    props: { path: '/authentication/404' },
    component: <NotFound />,
  },
  // {
  //   props: { path: '/forgot' },
  //   component: <ForgotPassword />,
  // },
  {
    props: { exact: true, path: '/profile' },
    component: <Profile />,
  },
  {
    props: { exact: true, path: '/profile/edit' },
    component: <EditProfile />,
  },
  {
    props: { exact: true, path: '/profile/changepassword' },
    component: <ChangePassword />,
  },
  // {
  //   props: { path: '/logout' },
  //   component: <Logout />,
  // },
]

export default AccountRoutes
