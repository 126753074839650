import React from 'react'

import { SvgIcon } from '@material-ui/core'

export default function Services(props) {
  const { svgcolor } = props

  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <g
        id="Group_24785"
        data-name="Group 24785"
        transform="translate(21166 -16093)"
      >
        <rect
          id="Rectangle_13546"
          data-name="Rectangle 13546"
          width="30"
          height="30"
          transform="translate(-21166 16093)"
          fill="none"
        />
        <g
          id="Group_24784"
          data-name="Group 24784"
          transform="translate(-21639 15914)"
        >
          <rect
            id="Rectangle_7027"
            data-name="Rectangle 7027"
            width="29.968"
            height="29.968"
            transform="translate(473 179)"
            fill="none"
          />
          <g id="amenities" transform="translate(473 179)">
            <path
              id="Path_12333"
              data-name="Path 12333"
              d="M29.036,449.877h-.648l-.413-1.655A.293.293,0,0,0,27.69,448H2.309a.293.293,0,0,0-.284.222l-.413,1.655H.964A.955.955,0,0,0,0,450.77a.938.938,0,0,0,.936.984H29.062A.938.938,0,0,0,30,450.77a.955.955,0,0,0-.963-.893Z"
              transform="translate(0 -421.754)"
              fill={svgcolor}
            />
            <path
              id="Path_12334"
              data-name="Path 12334"
              d="M57.868,105.874h1.379v1.445a11.259,11.259,0,0,0-10.31,11.2v.468a.937.937,0,0,0-.936.982.954.954,0,0,0,.963.892h2.786v1.113a.293.293,0,0,0,.293.293H68.327a.293.293,0,0,0,.293-.293v-1.113h2.785a.954.954,0,0,0,.963-.892.937.937,0,0,0-.936-.982v-.468a11.259,11.259,0,0,0-10.31-11.2v-1.445H62.5a.955.955,0,0,0,.963-.892.937.937,0,0,0-.936-.982H57.841a.937.937,0,0,0-.936.982A.955.955,0,0,0,57.868,105.874Z"
              transform="translate(-45.185 -97.9)"
              fill={svgcolor}
            />
            <path
              id="Path_12335"
              data-name="Path 12335"
              d="M.938,241.877H2.815a.938.938,0,1,0,0-1.877H.938a.938.938,0,1,0,0,1.877Z"
              transform="translate(0 -225.938)"
              fill={svgcolor}
            />
            <path
              id="Path_12336"
              data-name="Path 12336"
              d="M448.938,241.877h1.877a.938.938,0,0,0,0-1.877h-1.877a.938.938,0,1,0,0,1.877Z"
              transform="translate(-421.754 -225.938)"
              fill={svgcolor}
            />
            <path
              id="Path_12337"
              data-name="Path 12337"
              d="M71.9,73.223A.938.938,0,1,0,73.223,71.9L71.9,70.569A.938.938,0,0,0,70.568,71.9Z"
              transform="translate(-66.17 -66.17)"
              fill={svgcolor}
            />
            <path
              id="Path_12338"
              data-name="Path 12338"
              d="M388,73.5a.936.936,0,0,0,.664-.275l1.327-1.327a.938.938,0,0,0-1.327-1.327L387.336,71.9A.94.94,0,0,0,388,73.5Z"
              transform="translate(-364.389 -66.173)"
              fill={svgcolor}
            />
            <path
              id="Path_12339"
              data-name="Path 12339"
              d="M240.938,3.754a.938.938,0,0,0,.939-.938V.938a.938.938,0,1,0-1.877,0V2.815A.939.939,0,0,0,240.938,3.754Z"
              transform="translate(-225.938)"
              fill={svgcolor}
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
