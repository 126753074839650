import React from 'react'

import { SvgIcon } from '@material-ui/core'

export default function Home(props) {
  const { svgcolor } = props
  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <g
        id="Group_24776"
        data-name="Group 24776"
        transform="translate(21342 -16093)"
      >
        <rect
          id="Rectangle_13546"
          data-name="Rectangle 13546"
          width="30"
          height="30"
          transform="translate(-21342 16093)"
          fill="none"
        />
        <g
          id="house_1_"
          data-name="house (1)"
          transform="translate(-21808 15492)"
        >
          <g
            id="Group_5951"
            data-name="Group 5951"
            transform="translate(469.627 606.657)"
          >
            <g id="Group_5950" data-name="Group 5950">
              <path
                id="Path_11046"
                data-name="Path 11046"
                d="M71.6,108.19,60.171,119.945v11.683a.878.878,0,0,0,.86.9h6.206V122.439a.887.887,0,0,1,.883-.892h6.969a.877.877,0,0,1,.871.892v10.094h6.1a.878.878,0,0,0,.859-.9V119.945Zm0,11.536a3.619,3.619,0,1,1,3.484-3.617A3.557,3.557,0,0,1,71.6,119.726Z"
                transform="translate(-60.171 -108.19)"
                fill={svgcolor}
              />
            </g>
          </g>
          <g
            id="Group_5955"
            data-name="Group 5955"
            transform="translate(478.403 621.847)"
          >
            <g id="Group_5954" data-name="Group 5954">
              <rect
                id="Rectangle_3819"
                data-name="Rectangle 3819"
                width="5.425"
                height="9.153"
                fill={svgcolor}
              />
            </g>
          </g>
          <g
            id="Group_5957"
            data-name="Group 5957"
            transform="translate(466 601)"
          >
            <g id="Group_5956" data-name="Group 5956">
              <path
                id="Path_11048"
                data-name="Path 11048"
                d="M29.808,14.328v0c-.3-.774-8.009-8.541-8.594-9.143L16.924.768a2.646,2.646,0,0,0-3.729,0c-.633.651-12.686,12.751-13,13.555a.014.014,0,0,0,0,0A2.773,2.773,0,0,0,.77,17.264a2.52,2.52,0,0,0,.742.515L14.439,4.6a.861.861,0,0,1,1.243,0L28.495,17.787a2.629,2.629,0,0,0,.734-.523A2.773,2.773,0,0,0,29.808,14.328Z"
                transform="translate(0 0)"
                fill={svgcolor}
              />
            </g>
          </g>
          <g
            id="Group_5959"
            data-name="Group 5959"
            transform="translate(486.97 602.818)"
          >
            <g id="Group_5958" data-name="Group 5958">
              <path
                id="Path_11049"
                data-name="Path 11049"
                d="M366.718,30.161H363.1a.868.868,0,0,0-.894.815l5.4,5.415V31.029A.863.863,0,0,0,366.718,30.161Z"
                transform="translate(-362.208 -30.161)"
                fill={svgcolor}
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
