import { yupResolver } from '@hookform/resolvers'
import { CopyRight } from '@ifca-root/react-component/src/components/AuthLayout/CopyRight'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { setAccessToken } from 'AccessToken'
import background from 'assets/images/HR-Background.jpg'
import Logo from 'assets/images/HotelX-Logo.png'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import { useDebtorLoginMutation } from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import { usePhoneValidation } from 'containers/helper/hooks/usePhoneValidation'
interface LoginProps {
  Email?: string
  Password?: string
  MobileNo?: string
}
interface ParamsProps {
  hotelID?: string
}
export const LoginSchema = yup.object().shape({
  Email: yup.string(),
  // .email()
  // .required('Email is required'),
  Password: yup.string().required('Password is required'),
})
export const Login = () => {
  let history = useHistory()
  let {
    hotelID = '271b1802-e743-11ea-adc1-0242ac120002',
  }: ParamsProps = useParams()
  const [openAlert, setOpenAlert] = useState(false)
  const [values, setValues] = useState(false)
  const [message, setMessage] = useState('')

  const [IsMobileNo, setIsMobileNo] = useState(false)

  const { handleSubmit, control, errors } = useForm<LoginProps>({
    defaultValues: {},
    mode: 'onBlur',
    resolver: yupResolver(LoginSchema),
  })
  console.log(errors)
  const { isValidPoneNumber, validatePhonePrefix } = usePhoneValidation()

  const handleCloseAlert = () => {
    setOpenAlert(false)
  }
  const [DebtorLogin] = useDebtorLoginMutation({
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message)
        setMessage(message)
        setOpenAlert(true)
      })
    },
    onCompleted: data => {
      if (data && data.DebtorLogin.accessToken) {
        setAccessToken(data.DebtorLogin.accessToken)
        localStorage.setItem('DebtorInfo', JSON.stringify(data.DebtorLogin))
      }

      history.push('/home')
    },
  })
  const ValidateEmail = email => {
    var rgx = /\S+@\S+\.\S+/
    return rgx.test(email)
  }
  const onSubmit = data => {
    console.log(data)
    DebtorLogin({
      variables: {
        Email: !IsMobileNo && ValidateEmail(data.Email) ? data.Email : null,
        Login: !IsMobileNo && !ValidateEmail(data.Email) ? data.Email : null,
        Password: data.Password,
        MobileNo: IsMobileNo ? validatePhonePrefix(data.MobileNo) : null,
      },
    })
    // .then(i => {
    //   console.log(i)
    //   return i.data.DebtorLogin.ID !== '' ? history.push('/') : null
    // })
  }
  const password = document.querySelector('#Password')
  const handleClickShowPassword = () => {
    setValues(!values)
    const type =
      password.getAttribute('type') === 'password' ? 'text' : 'password'
    password.setAttribute('type', type)
  }

  // const handleMouseDownPassword = event => {
  //   event.preventDefault()
  // }

  return (
    <>
      <StandardDialog
        fullWidth={true}
        open={openAlert}
        onClose={handleCloseAlert}
        sections={{
          header: {
            title: 'Login Error',
          },
          body: () => <div>{message}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => handleCloseAlert(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <AuthLayout logo={Logo} image={background}>
        <span className="page-title">Login</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            {IsMobileNo ? (
              <Controller
                as={
                  <PhoneInput
                    containerClass="contact-input-auth"
                    inputProps={{
                      name: 'Mobile No.',
                      required: true,
                    }}
                    specialLabel="Mobile No. *"
                    country={'my'} //Country prefix
                  />
                }
                name="MobileNo"
                label="Mobile No."
                type="tel"
                fullWidth
                helperText={errors?.MobileNo?.message}
                error={errors?.MobileNo ? true : false}
                margin="normal"
                control={control}
              />
            ) : (
              <Controller
                as={TextField}
                control={control}
                label="Login / Email"
                fullWidth
                name="Email"
                margin="normal"
                type="email"
                helperText={errors?.Email?.message}
                error={errors?.Email ? true : false}
              />
            )}

            <Controller
              as={TextField}
              control={control}
              fullWidth
              label="Password"
              id="Password"
              name="Password"
              type="password"
              margin="normal"
              helperText={errors?.Password?.message}
              error={errors?.Password ? true : false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Password"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {values ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div
              className=" color-orange click-text"
              style={{
                textAlign: 'center',
                // color: '#ffffff8a',
              }}
              onClick={() => setIsMobileNo(!IsMobileNo)}
            >
              <span className="desc m-r-4">
                Login with {IsMobileNo ? 'Login ID / Email' : 'Mobile No'}
              </span>
            </div>
            <div className="other-auth">
              <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="Remember Me"
              />
              <span
                className="forget-link"
                onClick={() => history.push('/forgot')}
              >
                Forget Password?
              </span>
            </div>

            <Button
              variant="contained"
              color="primary"
              className="login-btn"
              onClick={() => handleSubmit(onSubmit)()}
            >
              Login
            </Button>
          </div>
        </form>

        <CopyRight />
      </AuthLayout>
    </>
  )
}
