import React, { lazy } from 'react'
const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  }))
)
const SubMenu = lazy(() =>
  import('./SubMenu').then(module => ({
    default: module.SubMenu,
  }))
)
const AddBooking = lazy(() =>
  import('./AddBooking').then(module => ({
    default: module.AddBooking,
  }))
)
const EditBooking = lazy(() =>
  import('./EditBooking').then(module => ({
    default: module.EditBooking,
  }))
)
const RoomingList = lazy(() =>
  import('./RoomingList/RoomingList').then(module => ({
    default: module.RoomingList,
  }))
)
const GuestList = lazy(() =>
  import('./RoomingList/GuestList').then(module => ({
    default: module.GuestList,
  }))
)
const GuestForm = lazy(() =>
  import('./RoomingList/GuestForm').then(module => ({
    default: module.GuestForm,
  }))
)
const HotelList = lazy(() =>
  import('./Hotel/HotelList').then(module => ({
    default: module.HotelList,
  }))
)
const HotelPackage = lazy(() =>
  import('./Hotel/HotelPackage').then(module => ({
    default: module.HotelPackage,
  }))
)
const HotelDetail = lazy(() =>
  import('./Hotel/HotelDetail').then(module => ({
    default: module.HotelDetail,
  }))
)
const HotelRoomTypeDetail = lazy(() =>
  import('./Hotel/HotelRoomTypeDetail').then(module => ({
    default: module.HotelRoomTypeDetail,
  }))
)
const HotelBookingForm = lazy(() =>
  import('./Hotel/HotelBookingForm').then(module => ({
    default: module.HotelBookingForm,
  }))
)
const PaymentDocument = lazy(() =>
  import('./Hotel/PaymentDocument').then(module => ({
    default: module.PaymentDocument,
  }))
)

const AdvpaymentList = lazy(() =>
  import('./AdvancePayment/AdvpaymentList').then(module => ({
    default: module.AdvpaymentList,
  }))
)

const AdvancePaymentDoc = lazy(() =>
  import('./AdvancePayment/AdvpaymentDoc').then(module => ({
    default: module.AdvancePaymentDoc,
  }))
)

export const addBookingPath = '/add'
export const subMenuPath = '/submenu'
export const editBookingPath = '/submenu/edit'
export const roomingListPath = '/submenu/roominglist'
export const roomingGuestListPath = '/submenu/roominglist/guest'
export const roomingGuestAddPath = '/submenu/roominglist/guest/add'
export const roomingGuestEditPath = '/submenu/roominglist/guest/edit'
export const AdvpaymentListPath = '/submenu/advancepayment'
export const advancePaymentDocPath = '/submenu/advancepayment/folio'

const HomeRoutes = [
  {
    props: { exact: true, path: '/home' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/home/redirect/:status' },
    component: <Home />,
  },
  {
    props: { exact: true, path: subMenuPath },
    component: <SubMenu />,
  },
  {
    props: { exact: true, path: addBookingPath },
    component: <AddBooking />,
  },
  {
    props: { exact: true, path: editBookingPath },
    component: <EditBooking />,
  },
  {
    props: { exact: true, path: roomingListPath },
    component: <RoomingList />,
  },
  {
    props: { exact: true, path: roomingGuestListPath },
    component: <GuestList />,
  },
  {
    props: { exact: true, path: roomingGuestAddPath },
    component: <GuestForm mode={'Add'} />,
  },
  {
    props: { exact: true, path: roomingGuestEditPath },
    component: <GuestForm mode={'Edit'} />,
  },
  {
    props: { exact: true, path: '/booking/search/hotel-list' },
    component: <HotelList />,
  },
  {
    props: { exact: true, path: '/booking/search/hotel-list/packages' },
    component: <HotelPackage />,
  },
  {
    props: {
      exact: true,
      path: '/booking/search/hotel-list/packages/hotel-detail',
    },
    component: <HotelDetail />,
  },
  {
    props: {
      exact: true,
      path: '/booking/search/hotel-list/packages/book-summary',
    },
    component: <HotelBookingForm />,
  },
  {
    props: {
      exact: true,
      path: '/booking/search/hotel-list/packages/roomtype-detail',
    },
    component: <HotelRoomTypeDetail />,
  },
  {
    props: { exact: true, path: '/hotel/detail/payment/redirect/:status' },
    component: <PaymentDocument mode="booking" />,
  },
  {
    props: { exact: true, path: AdvpaymentListPath },
    component: <AdvpaymentList />,
  },
  {
    props: { exact: true, path: advancePaymentDocPath },
    component: <AdvancePaymentDoc mode={'Booking'} type={'Receipt'} />,
  },
]

export default HomeRoutes
