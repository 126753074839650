import { format, parseISO } from 'date-fns'

export const formatDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd MMM yyyy') : 'NA'
}
export const formatTime = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'p') : 'NA'
}

export const dateTimeFormat = (date: string, pattern: string) => {
  const result = parseISO(date)
  return date ? format(result, pattern) : 'NA'
}
