import React, { lazy } from 'react'
import { LedgerType } from './LedgerListing'

const AccountStatus = lazy(() =>
  import('./AccountStatus').then(module => ({
    default: module.AccountStatus,
  }))
)
// const InvoiceDocument = lazy(() =>
//   import('./InvoiceDocument').then(module => ({
//     default: module.InvoiceDocument,
// }))
// )
const LedgerListing = lazy(() =>
  import('./LedgerListing').then(module => ({
    default: module.LedgerListing,
  }))
)
const DebtorDocument = lazy(() =>
  import('./Document/DebtorDocument').then(module => ({
    default: module.DebtorDocument,
  }))
)
const DebtorInvoice = lazy(() =>
  import('./Document/DebtorInvoice').then(module => ({
    default: module.DebtorInvoice,
  }))
)
const DebtorReceipt = lazy(() =>
  import('./Document/DebtorReceipt').then(module => ({
    default: module.DebtorReceipt,
  }))
)
const DebtorRefund = lazy(() =>
  import('./Document/DebtorRefund').then(module => ({
    default: module.DebtorRefund,
  }))
)
const AccountStatusRoutes = [
  {
    props: { exact: true, path: '/account-status' },
    component: <AccountStatus />,
  },
  {
    props: { exact: true, path: '/account-status/invoice' },
    component: <LedgerListing ledgerType={LedgerType.Invoices} />,
  },
  {
    props: { exact: true, path: '/account-status/invoice/document' },
    component: <DebtorInvoice ledgerType={LedgerType.Invoices} />,
  },
  {
    props: { exact: true, path: '/account-status/debit' },
    component: <LedgerListing ledgerType={LedgerType.DebitNotes} />,
  },
  {
    props: { exact: true, path: '/account-status/debit/document' },
    component: <DebtorDocument ledgerType={LedgerType.DebitNotes} />,
  },
  {
    props: { exact: true, path: '/account-status/credit' },
    component: <LedgerListing ledgerType={LedgerType.CreditNotes} />,
  },
  {
    props: { exact: true, path: '/account-status/credit/document' },
    component: <DebtorDocument ledgerType={LedgerType.CreditNotes} />,
  },
  {
    props: { exact: true, path: '/account-status/receipt' },
    component: <LedgerListing ledgerType={LedgerType.OfficialReceipt} />,
  },
  {
    props: { exact: true, path: '/account-status/receipt/document' },
    component: <DebtorReceipt ledgerType={LedgerType.OfficialReceipt} />,
  },
  {
    props: { exact: true, path: '/account-status/refund' },
    component: <LedgerListing ledgerType={LedgerType.Refund} />,
  },
  {
    props: { exact: true, path: '/account-status/refund/document' },
    component: <DebtorRefund ledgerType={LedgerType.Refund} />,
  },
  // {
  //   props: { exact: true, path: '/account-status/invoice' },
  //   component: <InvoiceDocument />,
  // },
]

export default AccountStatusRoutes
