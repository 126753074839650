import routes from '../Router/RootRoutes'

export interface GlobalState {
  routes: any
  drawerOpen: boolean
  bottomNavPosition?: number
  hotelOrGuest?: string
  checkInRequired?: boolean
  bookingDetails: any
  Errors: any
}
export const GlobalInitialState: GlobalState = {
  routes: routes,
  drawerOpen: true,
  bottomNavPosition: 0,
  hotelOrGuest: '',
  checkInRequired: true,
  bookingDetails: null,
  Errors: {
    Error: false,
    Message: '',
  },
}

export function RootReducer(state, action) {
  switch (action.type) {
    case 'routes':
      return {
        ...state,
        routes: action.payload,
      }
    case 'drawerOpen':
      return { ...state, drawerOpen: action.payload }
    case 'bottomNavPosition':
      return { ...state, bottomNavPosition: action.payload }
    case 'hotelOrGuest':
      return { ...state, hotelOrGuest: action.payload }
    case 'checkInRequired':
      return { ...state, checkInRequired: action.payload }
    case 'bookingDetails':
      return { ...state, bookingDetails: action.payload }
    case 'Errors':
      return { ...state, Errors: action.payload }
    default:
      throw new Error()
  }
}
