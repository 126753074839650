import React, { lazy } from 'react'
const Booking = lazy(() =>
  import('./Booking').then(module => ({
    default: module.Booking,
  }))
)
const EditBooking = lazy(() =>
  import('./EditBooking').then(module => ({
    default: module.EditBooking,
  }))
)
const EditRoomType = lazy(() =>
  import('./EditRoomType').then(module => ({
    default: module.EditRoomType,
  }))
)
const GuestList = lazy(() =>
  import('./GuestList').then(module => ({
    default: module.GuestList,
  }))
)
const ContractDetails = lazy(() =>
  import('./ContractDetails').then(module => ({
    default: module.ContractDetails,
  }))
)
const AdvancePaymentListing = lazy(() =>
  import('./AdvancePaymentListing').then(module => ({
    default: module.AdvancePaymentListing,
  }))
)
const bookingRoutes = [
  {
    props: { exact: true, path: '/booking' },
    component: <Booking />,
  },
  {
    props: { exact: true, path: '/booking/editbooking' },
    component: <EditBooking />,
  },
  {
    props: { exact: true, path: '/booking/editroomtype' },
    component: <EditRoomType />,
  },
  {
    props: { exact: true, path: '/booking/editroomtype/guestlist' },
    component: <GuestList />,
  },
  {
    props: { exact: true, path: '/booking/advancepayment' },
    component: <AdvancePaymentListing />,
  },
  {
    props: { exact: true, path: '/booking/contract' },
    component: <ContractDetails />,
  },
]

export default bookingRoutes
