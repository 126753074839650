import React, { lazy } from 'react'
const DebtorProfile = lazy(() =>
  import('./DebtorProfile').then(module => ({
    default: module.DebtorProfile,
  }))
)
const RoomTypeAllot = lazy(() =>
  import('./RoomTypeAllot').then(module => ({
    default: module.RoomTypeAllot,
  }))
)

export const roomTypeAllotPath = '/debtorprofile/roomtypeallot'
const DebtorProfileRoutes = [
  {
    props: { exact: true, path: '/debtorprofile' },
    component: <DebtorProfile />,
  },
    {
    props: { exact: true, path: roomTypeAllotPath },
    component: <RoomTypeAllot />,
  },
]

export default DebtorProfileRoutes
